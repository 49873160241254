import {
  Box,
  Flex,
  IconButton,
  Tooltip,
  useBreakpointValue,
} from "@chakra-ui/react"
import { EntityId } from "@jackfruit/common"
import React, { memo, useState } from "react"
import { useTranslation } from "react-i18next"
import { FiCopy, FiEdit, FiEye, FiTrash2 } from "react-icons/fi"
import { useAllPageUploadsAreReadyForLineItem } from "~/hooks/useAllPageUploadsAreReadyForLineItem"
import { interactionResponse } from "~/services/webvitals/defer"

interface Props {
  lineItemId: EntityId
  isReadOnly: boolean
  onView: () => void
  onEdit: () => void
  onDelete: () => void
  onDuplicate: () => void
}

const LineItemPreviewOverlay: React.FC<Props> = ({
  lineItemId,
  isReadOnly,
  onView,
  onEdit,
  onDelete,
  onDuplicate,
}) => {
  const [isActive, setIsActive] = useState(false)
  const shouldShowTools = useBreakpointValue({ base: true, md: false })
  const showTools = isActive || shouldShowTools
  const allUploadsAreReady = useAllPageUploadsAreReadyForLineItem(lineItemId)

  return (
    <Box
      onMouseEnter={async () => {
        await interactionResponse()
        setIsActive(true)
      }}
      onMouseLeave={async () => {
        await interactionResponse()
        setIsActive(false)
      }}
      position="absolute"
      top={0}
      left={0}
      width="100%"
      height="100%"
    >
      <Box
        opacity={isActive ? 0.4 : 0}
        transition="all 0.2s ease-in-out"
        position="absolute"
        top={0}
        left={0}
        borderTopRadius="lg"
        backgroundColor="gray.800"
        width="100%"
        height="100%"
      />

      <Flex
        data-testid={`lineItemPreviewOverlayButtonWrapper-${lineItemId}`}
        position="relative"
        justifyContent="flex-end"
        top={0}
        right={0}
        width="100%"
        height="100%"
        p={4}
      >
        {isReadOnly && allUploadsAreReady && (
          <ReadyTooltip
            showTools={showTools}
            onDelete={onDelete}
            onView={onView}
          />
        )}
        {!isReadOnly && allUploadsAreReady && (
          <NotReadyTooltip
            showTools={showTools}
            onDelete={onDelete}
            onDuplicate={onDuplicate}
            onEdit={onEdit}
          />
        )}
        {!allUploadsAreReady && (
          <NotUploadReady
            showTools={showTools}
            allUploadsAreReady={allUploadsAreReady}
          />
        )}
      </Flex>
    </Box>
  )
}

interface TooltipProps {
  showTools?: boolean
  onView: () => void
  onDelete: () => void
}

const ReadyTooltipComponent: React.FC<TooltipProps> = ({
  showTools = false,
  onDelete,
  onView,
}) => {
  const { t } = useTranslation()

  return (
    <>
      <Tooltip
        hasArrow
        label={t("components.cart.LineItemPreviewOverlay.removeItem")}
        bg="gray.800"
      >
        <IconButton
          id="deleteButton"
          transition="all 0.2s ease-in-out"
          position="absolute"
          right={showTools ? "65px" : 4}
          zIndex={2}
          ml={2}
          boxShadow="md"
          size="md"
          colorScheme="gray"
          rounded="full"
          aria-label={t("components.cart.LineItemPreviewOverlay.remove")}
          icon={<FiTrash2 />}
          onClick={onDelete}
        />
      </Tooltip>
      <Tooltip
        hasArrow
        label={t("components.cart.LineItemPreviewOverlay.viewItem")}
        bg="gray.800"
      >
        <IconButton
          id="viewButton"
          transition="all 0.2s ease-in-out"
          position="absolute"
          right={4}
          zIndex={3}
          ml={2}
          boxShadow="md"
          size="md"
          colorScheme="gray"
          rounded="full"
          aria-label={t("components.cart.LineItemPreviewOverlay.view")}
          icon={<FiEye />}
          onClick={onView}
        />
      </Tooltip>
    </>
  )
}

const ReadyTooltip = memo(ReadyTooltipComponent)

interface NotReadyTooltipProps {
  showTools?: boolean
  onDelete: () => void
  onDuplicate: () => void
  onEdit: () => void
}

const NotReadyTooltipComponent: React.FC<NotReadyTooltipProps> = ({
  showTools = false,
  onDelete,
  onDuplicate,
  onEdit,
}) => {
  const { t } = useTranslation()
  return (
    <>
      <Tooltip
        hasArrow
        label={t("components.cart.LineItemPreviewOverlay.removeItem")}
        bg="gray.800"
      >
        <IconButton
          id="deleteButton"
          transition="all 0.2s ease-in-out"
          position="absolute"
          right={showTools ? "115px" : 4}
          zIndex={1}
          ml={2}
          boxShadow="md"
          size="md"
          colorScheme="gray"
          rounded="full"
          aria-label={t("components.cart.LineItemPreviewOverlay.remove")}
          icon={<FiTrash2 />}
          onClick={onDelete}
        />
      </Tooltip>
      <Tooltip
        hasArrow
        label={t("components.cart.LineItemPreviewOverlay.duplicateItem")}
        bg="gray.800"
      >
        <IconButton
          transition="all 0.2s ease-in-out"
          position="absolute"
          right={showTools ? "65px" : 4}
          zIndex={2}
          ml={2}
          boxShadow="md"
          size="md"
          colorScheme="gray"
          rounded="full"
          aria-label={t("components.cart.LineItemPreviewOverlay.duplicate")}
          icon={<FiCopy />}
          onClick={onDuplicate}
          className="p-prints-add" // GTM: Add new size
        />
      </Tooltip>
      <Tooltip
        hasArrow
        label={t("components.cart.LineItemPreviewOverlay.editItem")}
        bg="gray.800"
      >
        <IconButton
          id="editButton"
          transition="all 0.2s ease-in-out"
          position="absolute"
          right={4}
          zIndex={3}
          ml={2}
          boxShadow="md"
          size="md"
          colorScheme="gray"
          rounded="full"
          aria-label={t("components.cart.LineItemPreviewOverlay.edit")}
          icon={<FiEdit />}
          onClick={onEdit}
        />
      </Tooltip>
    </>
  )
}

const NotReadyTooltip = memo(NotReadyTooltipComponent)

interface NotUploadReadyProps {
  showTools?: boolean
  allUploadsAreReady: boolean
}

const NotUploadReadyComponent: React.FC<NotUploadReadyProps> = ({
  showTools = false,
  allUploadsAreReady = false,
}) => {
  const { t } = useTranslation()
  return (
    <IconButton
      display={showTools ? "inline-flex" : "none"}
      boxShadow="md"
      size="md"
      colorScheme="gray"
      rounded="full"
      aria-label={t("components.cart.LineItemPreviewOverlay.loading")}
      isLoading={!allUploadsAreReady}
    />
  )
}

const NotUploadReady = memo(NotUploadReadyComponent)

export default LineItemPreviewOverlay
