import { Box, Flex, HStack, IconButton, useDisclosure } from "@chakra-ui/react"
import { EntityId } from "@jackfruit/common"
import { clamp } from "lodash"
import React, { useCallback } from "react"
import { useTranslation } from "react-i18next"
import { FiMinus, FiPlus } from "react-icons/fi"
import ConfirmationModal from "~/components/ConfirmationModal"
import Editor from "~/components/editors/Editor"
import FullScreen from "~/components/FullScreen"
import { useLineItem, useLineItemActions } from "~/hooks/useLineItem"
import { useProcessActions } from "~/hooks/useProcessActions"
import { interactionResponse } from "~/services/webvitals/defer"
import LinteItemPreviewImage from "./LineItemPreviewImage"
import LineItemPreviewModal from "./LineItemPreviewModal"
import LineItemPreviewOverlay from "./LineItemPreviewOverlay"
import LineItemPreviewProductSelectorWrapper from "./LineItemPreviewProductSelectorWrapper"
import LineItemPreviewProductTemplateSelectorWrapper from "./LineItemPreviewProductTemplateSelectorWrapper"
import LineItemTotalPrice from "./LineItemTotalPrice"

interface Props {
  lineItemId: EntityId
  lineItemQuantityLimit: number
}

const LineItemPreview2: React.FC<Props> = ({
  lineItemId,
  lineItemQuantityLimit,
}) => {
  const { t } = useTranslation()
  const { lineItem } = useLineItem(lineItemId)
  const { isEditing } = lineItem

  const {
    isOpen: isConfirmOpen,
    onOpen: onConfirmOpen,
    onClose: onConfirmClose,
  } = useDisclosure()

  const {
    isOpen: isPreviewOpen,
    onOpen: onPreviewOpen,
    onClose: onPerviewClose,
  } = useDisclosure()

  const process = useProcessActions()
  const { setEditing } = useLineItemActions()

  const { updateProduct, updateQuantity, remove } = useLineItemActions()

  const handleQuantityInc = useCallback(async () => {
    await interactionResponse()
    const newQuantity = clamp(lineItem.quantity + 1, 1, lineItemQuantityLimit)
    updateQuantity(lineItemId, newQuantity)

    if (lineItem.quantity !== newQuantity) {
      process.fireAnalyticEvent({
        eventType: "addOneItemToCart",
        data: { lineItemId },
      })
    }
  }, [
    lineItem.quantity,
    lineItemId,
    lineItemQuantityLimit,
    process,
    updateQuantity,
  ])

  const handleQuantityDec = useCallback(async () => {
    await interactionResponse()

    const newQuantity = clamp(lineItem.quantity - 1, 1, lineItemQuantityLimit)
    updateQuantity(lineItemId, newQuantity)

    if (lineItem.quantity !== newQuantity) {
      process.fireAnalyticEvent({
        eventType: "removeOneItemFromCart",
        data: { lineItemId },
      })
    }
  }, [
    lineItem.quantity,
    lineItemId,
    lineItemQuantityLimit,
    process,
    updateQuantity,
  ])

  const handleProductChange = useCallback(
    async (productId: EntityId) => {
      await interactionResponse()
      updateProduct(lineItemId, productId)
    },
    [lineItemId, updateProduct]
  )

  const handleView = useCallback(async () => {
    await interactionResponse()
    onPreviewOpen()
  }, [onPreviewOpen])

  const handleEdit = useCallback(async () => {
    await interactionResponse()
    setEditing(lineItemId, true)
  }, [lineItemId, setEditing])

  const handleDuplicate = useCallback(async () => {
    await interactionResponse()
    process.duplicateLineItem({
      lineItemId: lineItemId,
    })
  }, [lineItemId, process])

  const handleDelete = useCallback(async () => {
    await interactionResponse()
    remove(lineItemId)
  }, [lineItemId, remove])

  const onEditionComplete = useCallback(async () => {
    await interactionResponse()
    setEditing(lineItemId, false)

    process.updateOrderSummary({
      reason: "item editing completed",
      reasonType: "lineItemUpdated",
    })
  }, [lineItemId, process, setEditing])

  return (
    <>
      <LineItemPreviewModal
        isOpen={isPreviewOpen}
        onClose={onPerviewClose}
        lineItemId={lineItemId}
      />
      {isEditing && (
        <FullScreen>
          <Editor lineItemId={lineItemId} onComplete={onEditionComplete} />
        </FullScreen>
      )}

      <Box
        data-testid={`lineItemPreview-${lineItemId}`}
        w="full"
        borderWidth={1}
        borderColor="blackAlpha.200"
        borderRadius="lg"
        boxShadow="lg"
      >
        <Box
          height="185px"
          p={4}
          borderTopRadius="lg"
          bg="blackAlpha.50"
          position="relative"
          data-testid={`lineItemPreviewImage-${lineItem.productId}`}
        >
          <LinteItemPreviewImage lineItemId={lineItemId} />
          <LineItemPreviewOverlay
            lineItemId={lineItemId}
            isReadOnly={lineItem.isReadOnly}
            onView={handleView}
            onEdit={handleEdit}
            onDuplicate={handleDuplicate}
            onDelete={onConfirmOpen}
          />
        </Box>
        <Flex h="110px" direction="column" justifyContent="space-between">
          <Flex
            alignItems="center"
            justifyContent="space-between"
            flex={1}
            p={1}
          >
            {lineItem.isTemplate ? (
              <LineItemPreviewProductTemplateSelectorWrapper
                currentProductTemplateId={lineItem.productTemplateId!}
                blockTemplateId={lineItem.blockTemplateId!}
                currentProductId={lineItem.productId}
                onProductChange={handleProductChange}
                isDisabled={lineItem.isReadOnly}
              />
            ) : (
              <LineItemPreviewProductSelectorWrapper
                currentProductId={lineItem.productId}
                onProductChange={handleProductChange}
                isDisabled={lineItem.isReadOnly}
                lineItemId={lineItem.id}
                lineItemQuantityLimit={lineItemQuantityLimit}
              />
            )}
          </Flex>
          <Box height="2px" bg="blackAlpha.100" />
          <Flex
            px={3.5}
            alignItems="center"
            justifyContent="space-between"
            flex={1}
          >
            <HStack spacing={0}>
              <IconButton
                size="sm"
                rounded="full"
                aria-label={t("components.cart.LineItemPreview.remove")}
                icon={<FiMinus />}
                data-testid={`lineItemPreviewRemoveOneBtn-${lineItem.productId}`}
                className="decLineItemQuantity"
                onClick={handleQuantityDec}
              />
              <Box
                textAlign="center"
                width="1.75rem"
                data-testid={`lineItemPreviewQuality-${lineItem.productId}`}
              >
                {lineItem.quantity}
              </Box>
              <IconButton
                size="sm"
                rounded="full"
                data-testid={`lineItemPreviewAddoneBtn-${lineItem.productId}`}
                aria-label={t("components.cart.LineItemPreview.add")}
                icon={<FiPlus />}
                className="incLineItemQuantity"
                onClick={handleQuantityInc}
                disabled={lineItem.quantity >= lineItemQuantityLimit}
              />
            </HStack>
            <LineItemTotalPrice lineItemId={lineItemId} />
          </Flex>
        </Flex>
      </Box>
      <ConfirmationModal
        title={t("components.cart.LineItemPreview.Confirmation.title")}
        description={t(
          "components.cart.LineItemPreview.Confirmation.description"
        )}
        isOpen={isConfirmOpen}
        onClose={onConfirmClose}
        onConfirm={handleDelete}
      />
    </>
  )
}

export default React.memo(LineItemPreview2)
