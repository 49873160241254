import { Box } from "@chakra-ui/react"
import { EntityId } from "@reduxjs/toolkit"
import React, { useCallback } from "react"
import { useTranslation } from "react-i18next"
import { FiCheckCircle } from "react-icons/fi"
import { MdCropLandscape, MdCropPortrait } from "react-icons/md"
import { useLineItem, useLineItemActions } from "~/hooks/useLineItem"
import { interactionResponse } from "~/services/webvitals/defer"
import EditorButton from "./EditorButton"

interface Props {
  lineItemId: EntityId
}

const EditorOrientationTool: React.FC<Props> = ({ lineItemId }) => {
  const { t } = useTranslation()
  const { lineItem } = useLineItem(lineItemId)
  const { updateOrientation } = useLineItemActions()

  const orientation = lineItem.orientation

  const handleSetOrientationPortrait = useCallback(async () => {
    await interactionResponse()
    updateOrientation(lineItemId, "portrait")
  }, [updateOrientation, lineItemId])

  const handleSetOrientationLandscape = useCallback(async () => {
    await interactionResponse()
    updateOrientation(lineItemId, "landscape")
  }, [updateOrientation, lineItemId])

  const isPortrait = orientation === "portrait"
  const isLandscape = orientation === "landscape"

  return (
    <>
      <EditorButton
        id="orient-portrait"
        isSelected={isPortrait}
        borderBottomRadius={0}
        borderBottomWidth={1}
        leftIcon={<MdCropPortrait />}
        onClick={handleSetOrientationPortrait}
      >
        {t("components.editors.common.EditorOrientationTool.Portrait")}
        {isPortrait && (
          <Box position="absolute" right={4}>
            <FiCheckCircle />
          </Box>
        )}
      </EditorButton>
      <EditorButton
        id="orient-landscape"
        isSelected={isLandscape}
        borderTopRadius={0}
        leftIcon={<MdCropLandscape />}
        onClick={handleSetOrientationLandscape}
      >
        {t("components.editors.common.EditorOrientationTool.Landscape")}
        {isLandscape && (
          <Box position="absolute" right={4}>
            <FiCheckCircle />
          </Box>
        )}
      </EditorButton>
    </>
  )
}

export default EditorOrientationTool
